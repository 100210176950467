/** Generic Util class */
import { Router } from '@angular/router';
import { Location, PECount } from '../beans/dashboard/dashboard-module.bean';
import { FeedsService } from '../services/dashboard/feeds.service';
import { AppointmentsService } from '../services/scheduling/appointment/appointments.service';
import { DataCarrierService } from '../services/core/data-carrier.service';
import * as moment from 'moment';
import { DAYS_LEFT } from '../constants/scheduling.constants';
import saveAs from 'file-saver';
import { LocationInventory, ProviderLocation } from '../modules/shared/types';

export class AppUtil {
	/** Calculate number of days left to a specific date */
	public static calculateDaysLeft(datetime: Date): number {
		if (datetime !== null && typeof datetime === 'object') {
			const daysLeft = ((new Date().getTime() - datetime.getTime()) / (24 * 60 * 60 * 1000)).toFixed();
			return +daysLeft;
		} else {
			return -1;
		}
	}

	/** Formatting email string to lower case and removing additional spaces */
	public static getFormattedEmail(email: string) {
		return email.trim().toLowerCase();
	}

	/** Returning the parsed JSON response data */
	public static getResponseObj(data) {
		try {
			return JSON.parse(data);
		} catch (e) {
			return data;
		}
	}

	/** Adding specified number of days to a given date  */
	public static addDaysToDate(date: Date, numOfDays: number) {
		let millis = numOfDays * 24 * 60 * 60 * 1000;
		millis += date.getTime();
		return millis;
	}

	/** Checks if browser is IE */
	public static isIE() {
		const match = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);
		let isIE = false;
		if (match !== -1) {
			isIE = true;
		}
		return isIE;
	}

	/**
	 * Determine whether an input is a valid email
	 *
	 * @param input to test for email formatting validity
	 * @returns boolean true if an email, false if otherwise
	 */
	public static isValidEmail(input: string): boolean {
		const emailRegEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return emailRegEx.test(input.toLowerCase());
	}

	/**
	 * From an object of key value pairs, build out a query string
	 *
	 * @param valuePairs object key value pairs
	 * @param baseUrl optional baseUrl to analyze for initial delimiter
	 * @returns query string
	 */
	public static buildQueryString(valuePairs: object, baseUrl = ''): string {
		return Object.keys(valuePairs)
			.filter(key => Object.prototype.hasOwnProperty.call(valuePairs, key))
			.reduce((z, key, i) => {
				const delimiter = i === 0 && !baseUrl.includes('?') ? '?' : '&';
				return `${z}${delimiter}${key}=${valuePairs[key]}`;
			}, '');
	}

	/**
	 * Add UTM Parameters and any additional parameters to a given URL
	 *
	 * @param baseUrl base URL to add our parameters to via query string
	 * @param additionalParams any extra key value pairs to include
	 * @returns string with our final URL
	 */
	public static addParamsToURL(baseUrl: string, additionalParams = {}): string {
		const params = AppUtil.extractUTMParamsFromStorage(),
			validAdditionalParams = Object.assign({}, additionalParams);
		// remove invalid entries
		Object.keys(additionalParams).forEach(key => validAdditionalParams[key] || delete validAdditionalParams[key]);
		return `${baseUrl}${this.buildQueryString(Object.assign({}, params, validAdditionalParams), baseUrl)}`;
	}

	public static extractUTMParamsFromStorage(): object {
		let params = {};
		if (localStorage.getItem('utm')) {
			params = JSON.parse(localStorage.getItem('utm'));
			localStorage.removeItem('utm');
		}
		return params;
	}

	public static clearStorage(): void {
		localStorage.clear();
		sessionStorage.clear();
	}

	public static navigateToUrl(url: any, router: Router): void {
		this.clearStorage();
		if (router.routerState.snapshot.url.includes('embed')) {
			window.open(url, '_top');
		} else {
			window.location = url;
		}
	}

	public static constructUrlWithPathParams(url: any = '', params: any = []) {
		if (url && params) {
			for (let i = 0; i < params.length; i++) {
				url = url.replace(`{${i}}`, params[i]);
			}
			return url;
		}
	}

	public static maskCellPhone(cellPhone) {
		if (cellPhone) {
			while (cellPhone.length <= 10) {
				cellPhone = `X${cellPhone}`;
			}
			return cellPhone;
		}
	}

	public static getCellPhoneLastXDigits(number, x) {
		let cellPhone = number.replace(/[^0-9]/g, '');
		cellPhone = cellPhone.replace(new RegExp(`(\\d+)(\\d{${x}})`), '$2');
		return cellPhone;
	}


	/**
	 * Check if user is eligible for PE and redirect to scheduling page
	 */
	public static redirectToScheduling(
		router: Router,
		feedsService: FeedsService,
		appointmentService: AppointmentsService,
		dataCarrierService: DataCarrierService,
		extraNavigationCodes = null
	) {
		feedsService.getPECount().subscribe({
			next: response => {
				if (response.upcomingPECount > 0) {
					appointmentService.setUpcomingPE(true);
				} else {
					appointmentService.setUpcomingPE(false);
				}
				dataCarrierService.pushValue('peCount', response);
				if (
					response.pulseVirtualStatusDetails?.isEligibleForPW &&
					!response.pulseVirtualStatusDetails?.isPWAppointmentBooked &&
					!response.pulseVirtualStatusDetails?.isPWAppointmentBookable
				) {
					router.navigate(['health/mentor/hm']);
				} 
				else if (
					this.isEligibleToBookPE(response) ||
					(response.pulseVirtualStatusDetails?.isEligibleForPW &&
						!response.pulseVirtualStatusDetails?.isPWAppointmentBooked)
				) {
					// prepare final query params to send in scheduling regarding promo and referral codes
					const queryParamsToSend = this.extractValidKeys(extraNavigationCodes);
					// automatically redirect to scheduling/create
					router.navigate(['/scheduling'], { queryParams: queryParamsToSend });
				} else if (
					response.pulseVirtualStatusDetails?.isEligibleForPW &&
					response.pulseVirtualStatusDetails?.isPWAppointmentBooked
				) {
					router.navigate(['health/mentor/hm']);
				} 
				else if (
					response.pulseVirtualStatusDetails?.isPWOnlyClientUser && 
					!response.pulseVirtualStatusDetails?.isRosterEligible
				) {
					router.navigate(['health/mentor/hm']);
				} else if (
					response.pulseVirtualStatusDetails?.isPWOnlyClientUser && 
					response.pulseVirtualStatusDetails?.isRosterEligible
				) {
					router.navigate(['/scheduling']);
				}
				else {
					router.navigate(['dashboard']);
				}
				this.logNavigatorObject();
			},
			error: error => {
				router.navigate(['dashboard']);
				console.error(`[${AppUtil.name}][${this.redirectToScheduling.name}]`, 'An error occurred while fetching PE Status ', error);
			},
		});
	}

	private static logNavigatorObject() {
		const navig = {};
		for (const i in navigator) {
			if (navigator[i] instanceof Array || (typeof navigator[i] !== 'function' && typeof navigator[i] !== 'object')) {
				navig[i] = navigator[i];
			}
		}
		console['logToS3']([{ navigator: navig }]);
	}

	private static isEligibleToBookPE(response: PECount) {
		return (
			response.eligibleToBookPE &&
			response.upcomingPECount === 0 &&
			(response.pastPECount == 0 ||
				(response.pastPECount > 0 && this.findNumOfDays(response.lastPEDate) > DAYS_LEFT.PHYSICAL_EXAM))
		);
	}

	/**
	 * Removes keys from the object which have a value null
	 * @objectWithPossiblyNullValues An object
	 * @returns New object with non null values
	 */
	public static extractValidKeys(objectWithPossiblyNullValues: any) {
		if (!objectWithPossiblyNullValues) return {};
		return Object.keys(objectWithPossiblyNullValues).reduce((acc, curr) => {
			if (objectWithPossiblyNullValues[curr]) {
				acc[curr] = objectWithPossiblyNullValues[curr];
			}
			return acc;
		}, {});
	}

	private static findNumOfDays(date) {
		return moment(new Date()).diff(moment(new Date(date)), 'days');
	}
	/**
	 * Gets current year
	 */
	public static getCurrentYear() {
		return moment(new Date()).format('YYYY');
	}

	public static base64ToArrayBuffer(base64: string) {
		const byteString = window.atob(base64);
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return ab;
	}

	public static base64toBlob(dataURI: string, mime: string) {
		if (dataURI.startsWith('data:')) {
			const split = dataURI.split(',');
			dataURI = split[1];
			if (!mime) {
				mime = split[0].split(':')[1].split(';')[0];
			}
		}
		if (!mime) {
			throw new Error('MIME is required for Blob creation');
		}
		const ab = AppUtil.base64ToArrayBuffer(dataURI);
		return new Blob([ab], { type: mime });
	}

	public static base64toObjectURL(dataURI, mime) {
		return URL.createObjectURL(AppUtil.base64toBlob(dataURI, mime));
	}

	public static downloadFile(data: string | Blob, filename: string, mime: string) {
		saveAs(data, filename, mime);
	}

	public static deepClone<T>(object: T): T {
		return window.structuredClone(object);
	}

	public static getLocationsFromLocationsInventories(providerLocation: ProviderLocation[]): Location[] {
		if (providerLocation?.length) {
			return providerLocation.map(li => li.location);
		}
		return [];
	}
}
