<div class="header-wrapper" *ngIf="!auth">
	<nav id="header">
		<div class="navbar-container">
			<div class="container">
				<div class="row nav-padding">
					<div class="col-md-12">
						<div class="m-l">
							<div *ngIf="isSimplified">
								<div
									class="logo"
									[routerLink]="['/']"
									appGoogleAnalytics
									[eventData]="{
										eventCategory: 'Logo',
										eventAction: 'EHElogo',
										eventLabel: 'BookSimplified - Navigation Link - EHE logo'
									}">
									<img src="assets/images/ehe_pulse_logo.svg" class="icon" alt="logo" />
								</div>
							</div>
							<div *ngIf="!isSimplified">
								<div class="logo" [routerLink]="['/']">
									<img src="assets/images/ehe_pulse_logo.svg" class="icon" alt="logo" />
								</div>
							</div>
						</div>
						<div class="m-r" *ngIf="showPhone">
							<div
								class="phone-number"
								appEheAnalytics
								[data]="{
									pageUrl: '/dashboard',
									componentName: 'header',
									id: 'navbar-phone-number',
									placement: 'header-menu-phone-number'
								}">
								<p [translate]="'header-component.call-to-book-anon'">
									Call to Book:
									<a href="tel:{{ navPhoneNumber }}" attr.aria-label="{{ navPhoneNumber }}">{{ navPhoneNumber }}</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
</div>
<div class="auth-header-wrapper {{ wellness ? 'wellness-enabled' : 'wellness-disabled' }}" *ngIf="auth">
	<div class="navbar-container auth-header" id="header">
		<div class="container">
			<div class="row nav-padding">
				<div class="col-md-12">
					<div class="m-l" [routerLink]="['/dashboard']" routerLinkActive="active">
						<div
							class="logo"
							appEheAnalytics
							appGoogleAnalytics
							[eventData]="{
								eventCategory: 'Logo',
								eventAction: 'EHElogo',
								eventLabel: 'BookSimplified - Navigation Link - EHE logo'
							}">
							<img src="assets/images/ehe_pulse_logo.svg" class="icon" alt="logo" />
						</div>
					</div>
					<!--main menu container-->
					<ng-container *ngIf="!isSimplified">
						<div id="main-menu-nav" class="m-m" [hidden]="hideLinks">
							<div class="menu nav-menu hide-on-small">
								<ng-container *ngIf="(biometricsNonEligible && isLoaded) || !biometricsNonEligible">
									<ng-container *ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
										<ul class="text-18" *ngIf="isMatchedMember">
											<li
												[routerLink]="['/results']"
												routerLinkActive="active"
												appEheAnalytics
												[data]="{
													pageUrl: '/dashboard',
													redirectedTo: '/results',
													componentName: 'header',
													id: 'header-menu Health Portfolio',
													placement: 'header-menu'
												}">
												{{ navMyHealth }}
											</li>
											<!--Follow the template format if you wish to use except and only permission together, otherwise use directive with * on the element itself but only one at a time-->
											<ng-template
												[ngxPermissionsExcept]="[USER_LEVEL.CATALENT_CLIENT]"
												[ngxPermissionsOnly]="[MODULE_LEVEL.HM, MODULE_LEVEL.PW]">
												<li
													[routerLink]="['/health/mentor']"
													routerLinkActive="active"
													appEheAnalytics
													[data]="{
														pageUrl: '/dashboard',
														redirectedTo: '/health/mentor',
														componentName: 'header',
														id: 'header-menu Health Coach',
														placement: 'header-menu'
													}">
													{{ navMyMentors }}
												</li>
											</ng-template>
											<li
												[routerLink]="['/scheduling/appointments']"
												routerLinkActive="active"
												appEheAnalytics
												[data]="{
													pageUrl: '/dashboard',
													redirectedTo: '/scheduling/appointments',
													componentName: 'header',
													id: 'header-menu Appointments',
													placement: 'header-menu'
												}">
												{{ navMyAppointments }}
												<span class="badge" *ngIf="getUpcomingApptCount() > 0">
													{{ getUpcomingApptCount() }}
												</span>
											</li>
										</ul>
									</ng-container>
									<ul class="text-18" *ngIf="!isMatchedMember">
										<li class="non-clickable">{{ navMyHealth }}</li>
										<li class="non-clickable">{{ navMyMentors }}</li>
										<li class="non-clickable">{{ navMyAppointments }}</li>
									</ul>
									<ul class="text-18" *ngxPermissionsOnly="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
										<li
											[routerLink]="['/results']"
											[queryParams]="{ fromBiometrics: 'true' }"
											routerLinkActive="active"
											appEheAnalytics
											[data]="{
												pageUrl: '/dashboard',
												redirectedTo: '/results',
												componentName: 'header',
												id: 'header-menu Health Portfolio',
												placement: 'header-menu'
											}">
											BIOMETRIC SCREENING RESULTS
										</li>
									</ul>
								</ng-container>
							</div>
						</div>
					</ng-container>
					<div class="m-r" *ngIf="!isSimplified">
						<div class="mobile-menu mt-2">
							<div class="mobile-menu-icon" (click)="toggleNavbar()">
								<i class="material-icons">dehaze</i>
							</div>
						</div>
						<div class="menu auth-menu hide-on-small" id="header-menu" [hidden]="hideLinks">
							<ul>
								<!-- desktop -->
								<!--call to book container-->
								<ng-container>
									<li class="mr-0 ml-0 mr-3 menu-trigger">
										<div class="phone-number">
											<span
												[translate]="'header-component.call-to-book-auth'"
												appEheAnalytics
												[data]="{
													pageUrl: '/dashboard',
													componentName: 'header',
													id: 'navbar-phone-number',
													placement: 'header-menu-phone-number'
												}">
												Call to Book
												<a href="tel:{{ navPhoneNumber }}" aria-label="888.672.8172"> {{ navPhoneNumber }} </a>
											</span>
										</div>
									</li>
									<li
										class="mr-0 ml-0 mr-3 menu-trigger book-btn"
										*ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE, USER_LEVEL.UNMATCHED]"
										[routerLink]="[redirectUrl]"
										appEheAnalytics
										[data]="{
											pageUrl: '/dashboard',
											redirectedTo: redirectUrl,
											componentName: 'header',
											id: 'header-profile-menu Book',
											placement: 'header-profile-menu'
										}"
										appGoogleAnalytics
										[eventData]="{
											eventCategory: 'Button',
											eventAction: 'Book-Your-Exam-Portal',
											eventLabel: 'Portal - Navigation Book button'
										}">
										<div [translate]="'header-component.click-to-book'">
											<span> Click to Book </span>
										</div>
									</li>
								</ng-container>
								<li [matMenuTriggerFor]="profileMenu" class="mr-0 ml-0 menu-trigger">
									<span class="profile-name"
										>{{ profileService.getFirstName().value
										}}{{ profileService.getLastName().value | truncateLastName }}</span
									>
									<i class="fa fa-user-circle"></i>
									<span class="arrow"></span>
								</li>
								<mat-menu #profileMenu="matMenu" class="header-profile-menu" xPosition="before">
									<ng-container *ngIf="!biometricsNonEligible && isMatchedMember">
										<button
											mat-menu-item
											class="book-exam-link"
											[routerLink]="[redirectUrl]"
											appEheAnalytics
											[data]="{
												pageUrl: '/dashboard',
												redirectedTo: redirectUrl,
												componentName: 'header',
												id: 'header-profile-menu Book',
												placement: 'header-profile-menu'
											}">
											{{ navBookExam }}
										</button>
									</ng-container>
									<button
										mat-menu-item
										[routerLink]="['/account']"
										appEheAnalytics
										[data]="{
											pageUrl: '/dashboard',
											redirectedTo: '/account',
											componentName: 'header',
											id: 'header-profile-menu Account',
											placement: 'header-profile-menu'
										}">
										{{ navAccount }}
									</button>
									<ng-container *ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
										<ng-container *ngIf="isMatchedMember">
											<button
												mat-menu-item
												[routerLink]="['/messaging/mailbox/inbox']"
												appEheAnalytics
												[data]="{
													pageUrl: '/dashboard',
													redirectedTo: '/messaging/mailbox/inbox',
													componentName: 'header',
													id: 'header-profile-menu Mail',
													placement: 'header-profile-menu'
												}">
												{{ navMessages }}
												<span
													class="badge"
													*ngIf="this.messagingService.getCurrentMessageCount().getValue().inbox > 0"
													>{{ this.messagingService.getCurrentMessageCount().getValue().inbox }}</span
												>
											</button>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="!isMatchedMember">
										<button
											mat-menu-item
											class="non-clickable"
											appEheAnalytics
											[data]="{
												pageUrl: '/dashboard',
												redirectedTo: '/messaging/mailbox/inbox',
												componentName: 'header',
												id: 'header-profile-menu Mail',
												placement: 'header-profile-menu'
											}">
											{{ navMessages }}
										</button>
									</ng-container>
									<button
										mat-menu-item
										[routerLink]="['/faq/questions']"
										appEheAnalytics
										[data]="{
											pageUrl: '/dashboard',
											redirectedTo: '/faq/questions',
											componentName: 'header',
											id: 'header-profile-menu FAQ',
											placement: 'header-profile-menu'
										}">
										{{ navHelp }}
									</button>
									<ng-container *ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
										<button
											mat-menu-item
											*ngIf="tutorialDisabledFlag !== true && isMatchedMember"
											(click)="openTutorialModal()"
											appEheAnalytics
											[data]="{
												pageUrl: '/dashboard',
												redirectedTo: '/dashboard',
												componentName: 'header',
												id: 'header-profile-menu Tutorial',
												placement: 'header-profile-menu'
											}">
											{{ navTour }}
										</button>
									</ng-container>
									<button
										mat-menu-item
										(click)="logout()"
										appEheAnalytics
										[data]="{
											pageUrl: '/dashboard',
											redirectedTo: '/login',
											componentName: 'header',
											id: 'header-profile-menu Logout',
											placement: 'header-profile-menu'
										}">
										{{ navSignOut }}
									</button>
								</mat-menu>
							</ul>
						</div>
					</div>
					<div class="m-r mt-2">
						<div class="mobile-menu mr-3 mt-2">
							<div
								class="phone-number"
								appEheAnalytics
								[data]="{
									pageUrl: '/dashboard',
									componentName: 'header',
									id: 'navbar-phone-number',
									placement: 'header-menu-phone-number'
								}">
								<a href="tel:{{ navPhoneNumber }}" aria-label="888.672.8172"> {{ navPhoneNumber }} </a>
							</div>
						</div>
					</div>
					<div class="m-r" *ngIf="isSimplified">
						<div class="menu nav-menu hide-on-small">
							<ul class="text-18">
								<li
									[routerLink]="['/login']"
									[queryParams]="{ tab: 'login' }"
									routerLinkActive="active"
									appEheAnalytics
									[data]="{
										pageUrl: '/book',
										redirectedTo: '/login',
										componentName: 'header',
										id: 'header-menu booking-simplified',
										placement: 'header-menu'
									}"
									appGoogleAnalytics
									[eventData]="{
										eventCategory: 'Link',
										eventAction: 'LogIn-to-Portal',
										eventLabel: 'BookSimplified - Navigation Link - Login'
									}">
									LOGIN
								</li>
							</ul>
						</div>
					</div>
					<div class="menu auth-menu hide-on-small" id="header-menu" *ngIf="hideLinks || isSimplified">
						<ul>
							<li class="mr-0 ml-0 mr-3 menu-trigger" *ngIf="showPhone">
								<div class="phone-number">
									<span
										[translate]="'header-component.call-to-book-auth'"
										appEheAnalytics
										[data]="{
											pageUrl: '/dashboard',
											componentName: 'header',
											id: 'navbar-phone-number',
											placement: 'header-menu-phone-number'
										}">
										Call to Book
										<a href="tel:{{ navPhoneNumber }}" aria-label="888.672.8172"> {{ navPhoneNumber }} </a>
									</span>
								</div>
							</li>
							<li
								*ngIf="hideLinks"
								class="my-account-link"
								matTooltip="Access exam results, manage existing appointments, connect with Health Coaching and more."
								[routerLink]="['/results']"
								routerLinkActive="active"
								appEheAnalytics
								[data]="{
									pageUrl: '/scheduling/create/appointment',
									redirectedTo: '/my/health',
									componentName: 'header',
									id: 'header-menu my-health-link',
									placement: 'header-menu'
								}">
								My Health
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="wellness-header-wrapper" *ngIf="!healthHistory && wellness && !biometricsNonEligible">
		<ng-container *ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
			<div class="sub-menu hide-on-small">
				<div class="container">
					<div class="row">
						<div class="col-md-9 make-center">
							<ul class="menu">
								<li
									class="text-14 col-md-4"
									[routerLink]="['/results']"
									routerLinkActive="active"
									appEheAnalytics
									[data]="{
										pageUrl: '/dashboard',
										redirectedTo: '/results',
										componentName: 'header',
										id: 'megamenu Health Summary',
										placement: 'megamenu'
									}">
									Health Records
								</li>
								<li
									class="text-14 col-md-4"
									[routerLink]="['/vaxstatus']"
									routerLinkActive="active"
									appEheAnalytics
									[data]="{
										pageUrl: '/dashboard',
										redirectedTo: '/vaxstatus',
										componentName: 'header',
										id: 'megamenu VaxPass',
										placement: 'megamenu'
									}"
									appGoogleAnalytics
									[eventData]="{
										eventCategory: 'Tab',
										eventAction: 'Click - Vaccine Records Tab',
										eventLabel: 'VaxStatus - Vaccine Records Tab'
									}">
									Vaccine Records
								</li>
								<li
									class="text-14 col-md-4"
									[routerLink]="['/wellness/referral']"
									routerLinkActive="active"
									appEheAnalytics
									[data]="{
										pageUrl: '/dashboard',
										redirectedTo: '/wellness/referral',
										componentName: 'header',
										id: 'megamenu Referrals',
										placement: 'megamenu'
									}">
									Referrals
								</li>
								<li
									class="text-14 col-md-4"
									[routerLink]="['/assessment']"
									routerLinkActive="active"
									appEheAnalytics
									[data]="{
										pageUrl: '/dashboard',
										redirectedTo: '/ha-new',
										componentName: 'header',
										id: 'megamenu Health Assessment',
										placement: 'megamenu'
									}">
									Health Assessment
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<div class="mobile-menu-container" style="display: none" [ngClass]="{ show: navbarOpen }">
	<ng-container *ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
		<div class="mobile-menu-section" *ngIf="!biometricsNonEligible && isMatchedMember">
			<div
				class="book-exam-link"
				(click)="toggleNavbar()"
				[routerLink]="[redirectUrl]"
				routerLinkActive="active"
				appEheAnalytics
				[data]="{
					pageUrl: '/dashboard',
					redirectedTo: redirectUrl,
					componentName: 'header',
					id: 'header-menu Health Portfolio',
					placement: 'header-menu'
				}">
				<a>{{ navBookExam }}</a>
			</div>
			<div class="container">
				<ul>
					<li
						[routerLink]="['/results']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/results',
							componentName: 'header',
							id: 'header-menu Appointments',
							placement: 'header-menu'
						}">
						<a>{{ navMyHealth }}</a>
					</li>
					<li
						*ngxPermissionsExcept="[USER_LEVEL.CATALENT_CLIENT]"
						[routerLink]="['/health/mentor']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/health/mentor',
							componentName: 'header',
							id: 'header-menu Health Coach',
							placement: 'header-menu'
						}">
						<a>{{ navMyMentors }}</a>
					</li>
					<li
						[routerLink]="['/scheduling/appointments']"
						routerLinkActive="active"
						appEheAnalytics
						(click)="toggleNavbar()"
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/scheduling/appointments',
							componentName: 'header',
							id: 'header-menu Appointments',
							placement: 'header-menu'
						}">
						<a>
							{{ navMyAppointments }}
							<span class="badge upcoming-appointments" *ngIf="getUpcomingApptCount() > 0">
								{{ getUpcomingApptCount() }}
							</span>
						</a>
					</li>
					<li
						[routerLink]="['/messaging/mailbox/inbox']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/messaging/mailbox/inbox',
							componentName: 'header',
							id: 'megamenu Inbox',
							placement: 'megamenu'
						}">
						<a>{{ navMessages }}</a>
					</li>
					<li
						[routerLink]="['/account']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/account',
							componentName: 'header',
							id: 'megamenu Account',
							placement: 'megamenu'
						}">
						<a>{{ navAccount }}</a>
					</li>
					<li
						[routerLink]="['/faq/questions']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/faq/questions',
							componentName: 'header',
							id: 'megamenu FAQ',
							placement: 'megamenu'
						}">
						<a>{{ navHelp }}</a>
					</li>
					<li
						*ngIf="tutorialDisabledFlag !== true && isMatchedMember"
						(click)="openTutorialModal(); toggleNavbar()"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/dashboard',
							componentName: 'header',
							id: 'megamenu Tutorial',
							placement: 'megamenu'
						}">
						<a>{{ navTour }}</a>
					</li>
					<li>
						<a
							(click)="logout()"
							appEheAnalytics
							[data]="{
								pageUrl: '/dashboard',
								redirectedTo: '/login',
								componentName: 'header',
								id: 'megamenu Log Out',
								placement: 'megamenu'
							}"
							>{{ navSignOut }}</a
						>
					</li>
				</ul>
			</div>
		</div>
	</ng-container>
	<div class="mobile-menu-section" *ngIf="!isMatchedMember">
		<div class="container">
			<ul>
				<li>
					<a>{{ navMyHealth }}</a>
				</li>
				<li>
					<a>{{ navMyMentors }}</a>
				</li>
				<li>
					<a>{{ navMyAppointments }}</a>
				</li>
				<li>
					<a>{{ navMessages }}</a>
				</li>
				<li>
					<a
						[routerLink]="['/account']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/account',
							componentName: 'header',
							id: 'megamenu Account',
							placement: 'megamenu'
						}"
						>{{ navAccount }}</a
					>
				</li>
				<li>
					<a
						[routerLink]="['/faq/questions']"
						routerLinkActive="active"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/faq/questions',
							componentName: 'header',
							id: 'megamenu FAQ',
							placement: 'megamenu'
						}"
						>{{ navHelp }}</a
					>
				</li>
				<li *ngIf="tutorialDisabledFlag !== true && isMatchedMember">
					<a
						(click)="openTutorialModal(); toggleNavbar()"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/dashboard',
							componentName: 'header',
							id: 'megamenu Tutorial',
							placement: 'megamenu'
						}"
						>{{ navTour }}</a
					>
				</li>
				<li>
					<a
						(click)="logout()"
						appEheAnalytics
						[data]="{
							pageUrl: '/dashboard',
							redirectedTo: '/login',
							componentName: 'header',
							id: 'megamenu Log Out',
							placement: 'megamenu'
						}"
						>{{ navSignOut }}
					</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="mobile-menu-section" *ngxPermissionsOnly="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
		<ul>
			<li>
				<a
					[routerLink]="['/results']"
					[queryParams]="{ fromBiometrics: 'true' }"
					routerLinkActive="active"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/results',
						componentName: 'header',
						id: 'header-menu Health Portfolio',
						placement: 'header-menu'
					}"
					>Biometric Screening Results
				</a>
			</li>
			<li>
				<a
					[routerLink]="['/account']"
					routerLinkActive="active"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/account',
						componentName: 'header',
						id: 'megamenu Account',
						placement: 'megamenu'
					}"
					>{{ navAccount }}</a
				>
			</li>
			<li>
				<a
					[routerLink]="['/faq/questions']"
					routerLinkActive="active"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/faq/questions',
						componentName: 'header',
						id: 'megamenu FAQ',
						placement: 'megamenu'
					}"
					>{{ navHelp }}</a
				>
			</li>
			<li>
				<a
					(click)="logout()"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/login',
						componentName: 'header',
						id: 'megamenu Log Out',
						placement: 'megamenu'
					}"
					>{{ navSignOut }}
				</a>
			</li>
		</ul>
	</div>
</div>

<div class="mobile-inner-menu-container hide-on-large" *ngxPermissionsExcept="[USER_LEVEL.HUBBELL_NOT_ELIGIBLE]">
	<div
		*ngIf="!healthHistory"
		class="wellness-mobile-menu mobile-inner-menu"
		[routerLink]="['/wellness']"
		routerLinkActive="active">
		<div class="row">
			<div class="col header">
				<div
					class="mobile-sub-menu"
					[routerLink]="['/results']"
					routerLinkActive="active"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/results',
						componentName: 'header',
						id: 'megamenu Health Summary',
						placement: 'megamenu'
					}">
					Health Records
				</div>
				<div
					class="mobile-sub-menu"
					[routerLink]="['/wellness/vaxpass']"
					routerLinkActive="active"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/wellness/vaxpass',
						componentName: 'header',
						id: 'megamenu VaxPass',
						placement: 'megamenu'
					}">
					Vaccine Records
				</div>
				<div
					class="mobile-sub-menu"
					[routerLink]="['/wellness/referral']"
					routerLinkActive="active"
					appEheAnalytics
					[data]="{
						pageUrl: '/dashboard',
						redirectedTo: '/wellness/referral',
						componentName: 'header',
						id: 'megamenu Referrals',
						placement: 'megamenu'
					}">
					Referrals
				</div>
				<div
					class="mobile-sub-menu"
					[routerLink]="['/assessment']"
					routerLinkActive="active"
					*ngIf="routerUrl !== '/wellness/health/old'">
					Health Assessment
				</div>
				<div
					class="mobile-sub-menu"
					[routerLink]="['/wellness/health/old']"
					routerLinkActive="active"
					*ngIf="routerUrl === '/wellness/health/old'">
					Health Assessment
				</div>
			</div>
		</div>
	</div>
</div>

<div class="mobile-inner-menu-container hide-on-large">
	<div class="mobile-inner-menu">
		<ul id="responsiveMenu">
			<li
				class="mobile-sub-menu"
				[routerLink]="['/messaging/mailbox/inbox']"
				routerLinkActive="active"
				appEheAnalytics
				[data]="{
					pageUrl: '/dashboard',
					redirectedTo: '/messaging/mailbox/inbox',
					componentName: 'header',
					id: 'megamenu Inbox',
					placement: 'megamenu'
				}">
				{{ navMessages }} (
				<span>
					{{
						messagingService.getCurrentMessageCount().getValue().inbox > 0
							? messagingService.getCurrentMessageCount().getValue().inbox
							: '0'
					}}
				</span>
				)
			</li>
			<li
				class="mobile-sub-menu"
				[routerLink]="['/messaging/mailbox/sent']"
				routerLinkActive="active"
				appEheAnalytics
				[data]="{
					pageUrl: '/dashboard',
					redirectedTo: '/messaging/mailbox/sent',
					componentName: 'header',
					id: 'megamenu Sent',
					placement: 'megamenu'
				}">
				Sent (
				<span>
					{{
						messagingService.getCurrentMessageCount().getValue().sent > 0
							? messagingService.getCurrentMessageCount().getValue().sent
							: '0'
					}}
				</span>
				)
			</li>
			<li
				class="mobile-sub-menu"
				[routerLink]="['/messaging/compose']"
				routerLinkActive="active"
				appEheAnalytics
				[data]="{
					pageUrl: '/dashboard',
					redirectedTo: '/messaging/compose',
					componentName: 'header',
					id: 'megamenu Compose',
					placement: 'megamenu'
				}">
				Compose
			</li>
		</ul>
	</div>
</div>
